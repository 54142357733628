import Layout from "../page-components/layout"
import React from "react"
import BetriebsmarktContactSuche from "../pages-parts/betriebsmarkt/betriebsmarkt-contact-suche"

const BetriebsmarktContactSearchingPage = () => {
  return <Layout>
    <div className="container bg-white h-100">
      <div className="row">
        <BetriebsmarktContactSuche />
      </div>
    </div>
  </Layout>
};

export default BetriebsmarktContactSearchingPage;
